
import { Options, Prop } from "vue-property-decorator";
import UserService from "@/services/UserService";
import { IBroadcast } from "@/interfaces/IBroadcast";
import { PropType } from "vue";
import { ICrudOptions } from "@/interfaces/ICrudOptions";
import NewsItem from "@/components/Pages/News/NewsItem.vue";
import { NewsTypeEnum } from "@/enums/NewsTypeEnum";
import { mixins } from "vue-class-component";
import ModalMixin from "@/mixins/ModalMixin";
import { isPlatform, IonContent } from "@ionic/vue";

@Options({
  components: { NewsItem, IonContent },
})
export default class NewsList extends mixins(ModalMixin) {
  @Prop({ type: Boolean, default: () => false })
  public loading!: boolean;

  @Prop({ type: Array as PropType<Array<IBroadcast>>, default: () => [] })
  public broadcasts!: Array<IBroadcast>;

  @Prop({ type: Function, default: () => null })
  public markAsSeen!: (opt: ICrudOptions) => void;

  @Prop({ type: Boolean, default: () => false })
  public markAsSeenSuccess!: boolean;

  private documentSrc = "";

  public get driveSchoolName(): string {
    return UserService.getUser().drivingSchoolName;
  }

  private onClickBroadcast(broadcast: IBroadcast) {
    if (!broadcast.seen) {
      broadcast.seen = true;

      this.markAsSeen({
        resource:
          broadcast.newsType.id == NewsTypeEnum.BROADCAST
            ? `/broadcasts/mark-as-seen?id=${broadcast.id}`
            : `/messages/mark-as-seen?id=${broadcast.id}`,
        descriptionField: "",
        hideSuccessMessage: true,
      });
    }
  }

  public get isDesktop(): boolean {
    return isPlatform("desktop");
  }

  private onHideModal() {
    this.hideModal(null, "newsPopover");
  }

  private onClickPreview(item: any) {
    this.documentSrc = item?.body;
    this.showModal(null, "newsPopover");
  }
}
