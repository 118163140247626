import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ForwardHeader = _resolveComponent("ForwardHeader")!
  const _component_NewsHero = _resolveComponent("NewsHero")!
  const _component_b_col = _resolveComponent("b-col")!
  const _component_NewsList = _resolveComponent("NewsList")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_DefaultLayout = _resolveComponent("DefaultLayout")!

  return (_openBlock(), _createBlock(_component_DefaultLayout, null, {
    header: _withCtx(() => [
      _createVNode(_component_ForwardHeader)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_b_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_b_col, { xl: "3" }, {
            default: _withCtx(() => [
              _createVNode(_component_NewsHero)
            ]),
            _: 1
          }),
          _createVNode(_component_b_col, { xl: "9" }, {
            default: _withCtx(() => [
              _createVNode(_component_NewsList, {
                broadcasts: _ctx.broadcasts,
                loading: _ctx.broadcastsIsLoading,
                markAsSeen: _ctx.markAsSeen,
                markAsSeenSuccess: _ctx.markAsSeenSuccess
              }, null, 8, ["broadcasts", "loading", "markAsSeen", "markAsSeenSuccess"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}